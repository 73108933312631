import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

const CST = 'America/Chicago';

export const dayjsCST = (date?: dayjs.ConfigType) => {
  return dayjs(date).tz(CST);
};

// Helper functions to get the start and end of the day in CST
export const startOfDayCST = (date?: dayjs.ConfigType) => {
  return dayjsCST(date).startOf('day');
};

export const endOfDayCST = (date?: dayjs.ConfigType) => {
  const targetDate = dayjsCST(date);
  const DSTChangeDate = dayjsCST('2024-11-03');

  // Handle DST rollback case for November 4, 2024
  if (targetDate.isSame(DSTChangeDate.add(1, 'day'), 'day')) {
    // November 4, 2024: The end of the day is 11:59:59 PM CST (not extended by 1 hour)
    return targetDate.endOf('day').subtract(1, 'hour');
  }

  return targetDate.endOf('day');
};

