import { DatePicker } from "antd";
import { rangePresets } from "./charts/mock/chartsMock";
import { Dayjs } from "dayjs";
import { Dispatch, SetStateAction } from "react";
import { dayjsCST } from "../../../utils/dayjsConfig";

interface OwnProps {
  startDate: string;
  setStartDate: Dispatch<SetStateAction<string>>;
  endDate: string;
  setEndDate: Dispatch<SetStateAction<string>>;
  setSelectedStartDate: Dispatch<SetStateAction<number>>;
  setSelectedEndDate: Dispatch<SetStateAction<number>>;
  restrictToOneDay?: boolean;
}

const { RangePicker } = DatePicker;

export const CustomRangePicker = ({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  setSelectedStartDate,
  setSelectedEndDate,
  restrictToOneDay,
}: OwnProps) => {
  const getEndOfDayCST = (date: Dayjs) => {
    const DSTChangeDate = dayjsCST("2024-11-03");
    if (date.isSame(DSTChangeDate.add(1, "day"), "day")) {
      // Handle 2024-11-04 DST rollback case
      return date.endOf("day").subtract(1, "hour");
    }
    return date.endOf("day");
  };

  const onRangeChange = (dates: null | (Dayjs | null)[]) => {
    if (dates) {
      const startDate = dayjsCST(dates[0]);
      const endDate = dayjsCST(dates[1]);

      setStartDate(startDate.format());
      setEndDate(getEndOfDayCST(endDate).format());
      setSelectedStartDate(startDate.unix());
      setSelectedEndDate(getEndOfDayCST(endDate).unix());
    } else {
      console.log("Clear");
    }
  };

  const onDateChange = (date: Dayjs | null) => {
    if (date) {
      const selectedDateStart = dayjsCST(date).startOf("day");
      const selectedDateEnd = getEndOfDayCST(dayjsCST(date));

      setStartDate(selectedDateStart.format());
      setEndDate(selectedDateEnd.format());
      setSelectedStartDate(selectedDateStart.unix());
      setSelectedEndDate(selectedDateEnd.unix());
    } else {
      console.log("Clear");
    }
  };

  const disabledDate = (current: Dayjs) => {
    return current && current > dayjsCST().endOf("d");
  };

  return (
    <>
      {!restrictToOneDay && (
        <RangePicker
          presets={rangePresets}
          format="MM/DD/YYYY"
          onChange={onRangeChange}
          style={{ width: "100%" }}
          defaultValue={[dayjsCST(startDate), dayjsCST(endDate)]}
          disabledDate={disabledDate}
        />
      )}
      {restrictToOneDay && (
        <DatePicker
          format="MM/DD/YYYY"
          onChange={onDateChange}
          style={{ width: "100%", marginLeft: "8px" }}
          disabledDate={disabledDate}
          value={dayjsCST(endDate)} // Display the selected date
        />
      )}
    </>
  );
};
